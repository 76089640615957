/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect } from "react";
import {Link, Switch, Redirect, useLocation} from "react-router-dom";
import tawkTo from "tawkto-react";

import { toAbsoluteUrl } from "../../../../_metronic/_helpers";
import { ContentRoute } from "../../../../_metronic/layout";
import Login from "./Login";
import Registration from "./Registration";
import ForgotPassword from "./ForgotPassword";
import "../../../../_metronic/_assets/sass/pages/login/classic/login-1.scss";
import {ResetPassword} from "../index";

export function AuthPage() {
  const location = useLocation();
  const { REACT_APP_tawkToPropertyId, REACT_APP_tawkToKey } = process.env;

  useEffect(() => {
    tawkTo(REACT_APP_tawkToPropertyId, REACT_APP_tawkToKey)
  }, [])

  return (
    <>
      <div className="d-flex flex-column flex-root">
        {/*begin::Login*/}
        <div
          className="login login-1 login-signin-on d-flex flex-column flex-lg-row flex-column-fluid bg-white"
          id="kt_login"
        >
          {/*begin::Aside*/}
          <div
            className="login-aside d-flex flex-row-auto bgi-size-cover bgi-no-repeat p-10 p-lg-10"
          >
            {/*begin: Aside Container  backgroundImage: `url(${toAbsoluteUrl("/media/bg/new_back.png")})`, */}
            <div className="d-flex flex-row-fluid flex-column justify-content-between">
              {/* start:: Aside header */}
              <Link to="/" className="flex-column-auto mt-5 pb-lg-0 pb-10">
                <img
                  alt="Logo"
                  className="max-h-70px"
                  style={{height: 30}}
                  src={toAbsoluteUrl("/media/bg/vahlid_logo.png")}
                />
              </Link>
              {/* end:: Aside header */}
                
                
              {/* start:: Aside content style='color:#5A6474' */}
              <div className="flex-column-fluid d-flex flex-column justify-content-top" style={{marginTop:80}} >
                <h3 className="mb-5 aside-text"  style={{fontSize:35, color:"#FFFFFF" }} >
                  A safe and secure way to <br/> transact online!
                </h3>
                <img
                    className={"aside-img"}
                    style={{paddingTop:20}}
                    src={toAbsoluteUrl("/media/bg/new_pic.png")}
                />
              </div>
              <div className="d-flex justify-content-between">
                <div className="opacity-70 	" style={{color:'#FFFFFF'}}>
                  &copy; {new Date().getFullYear()} Vahlid 
                </div>
                <div className="d-flex" style={{ position:"relative", right:0}} >
                  <a href="https://vahlid.com/privacy-policy/"  style={{color:'#FFFFFF'}}>
                    Privacy
                  </a>
                  <a href="https://vahlid.com/terms-conditions/" className=" ml-10" style={{color:'#FFFFFF'}}>
                    Legal
                  </a>
                  {/*<Link to="/terms" className=" ml-10" style={{color:'#FFFFFF'}}>*/}
                  {/*  Contact*/}
                  {/*</Link>*/}
                </div>
              </div>


              {/* end:: Aside footer for desktop */}
            </div>
            {/*end: Aside Container*/}
          </div>
          {/*begin::Aside*/}

          {/*begin::Content*/}
          <div className="d-flex flex-column flex-row-fluid position-relative p-7 overflow-hidden">
            {/*begin::Content header*/}
            {
              location.pathname === "/auth/registration"
                ?
                <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
                    <span className="font-weight-bold text-dark-50">
                      Already have an account?
                    </span>
                    <Link
                        to="/auth/login"
                        className="font-weight-bold ml-2"
                        id="kt_login_signup"
                    >
                      Login
                    </Link>
                </div>
                  :
                <div className="position-absolute top-0 right-0 text-right mt-5 mb-15 mb-lg-0 flex-column-auto justify-content-center py-5 px-10">
                    <span className="font-weight-bold text-dark-50">
                      Don't have an account yet?
                    </span>
                    <Link
                        to="/auth/registration"
                        className="font-weight-bold ml-2"
                        id="kt_login_signup"
                    >
                      Sign Up!
                    </Link>
                </div>
            }
            {/*end::Content header*/}

            {/* begin::Content body */}
            <div className="d-flex flex-column-fluid flex-center mt-30 mt-lg-0">
              <Switch>
                <ContentRoute path="/auth/login" component={Login} />
                <ContentRoute
                  path="/auth/registration"
                  component={Registration}
                />
                <ContentRoute
                  path="/auth/forgot-password"
                  component={ForgotPassword}
                />
                <ContentRoute path={"/password-reset/:email/:hash"} component={ResetPassword} />

                <Redirect from="/auth" exact={true} to="/auth/login" />
                <Redirect to="/auth/login" />
              </Switch>
            </div>
            {/*end::Content body*/}

            {/* begin::Mobile footer */}
            {/*
               <div style={{backgroundColor:"red"}} className="d-flex d-lg-none flex-column-auto flex-column flex-sm-row justify-content-between align-items-center mt-5 p-5">
              <div className="text-dark-50 font-weight-bold order-2 order-sm-1 my-2">
                &copy; {new Date().getFullYear()} Vahlid
              </div>
              <div className="d-flex order-1 order-sm-2 my-2">
                <a href="https://vahlid.com/privacy-policy/" className="text-dark-75 text-hover-primary">
                  Privacy
                </a>
                <a
                  href="https://vahlid.com/terms-conditions/"
                  className="text-dark-75 text-hover-primary ml-4"
                >
                  Legal
                </a>
                
              </div>
            </div>
            */}
            
            {/* end::Mobile footer */}
          </div>
          {/*end::Content*/}
        </div>
        {/*end::Login*/}
      </div>
    </>
  );
}
